/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* No Sroll Display */
.noScrollcss::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.noutlined:focus {
  outline: none !important;
}


/* Hover Scroll */ 

.scroll-box {
  overflow-y: scroll;
  padding-right: 8px;
  cursor: pointer;
}

.scroll-box::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #fff;
}

/* Hover effects for the scrollbar */
.scroll-box:hover::-webkit-scrollbar-thumb {
  background-color: #C9D6DF;
  border-radius: 10px;
}


/* Trasparent */
.transparent-scroll {
  overflow: auto; /* Enable scrollbar when content overflows */
}

/* .content {
  Add padding if you want to prevent content from touching the edges
  padding: 10px;
} */

/* Customize scrollbar */
.transparent-scroll::-webkit-scrollbar {
  width: 10px; /* Width of scrollbar */
}

.transparent-scroll::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of scrollbar thumb */
}

.transparent-scroll::-webkit-scrollbar-track {
  background-color: transparent; /* Make scrollbar track transparent */
}